html{
    width: 100%;
}
body{
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    text-align: left;
    vertical-align: baseline;
}

a {
    text-decoration: none;
    &:hover, &:focus, &:active{
        text-decoration: none;
    }
}

input{
    outline: none;
    &:hover, &:focus, &:active{
        outline: none;
    }
}

textarea{
    outline: none;
    &:hover, &:focus, &:active{
        outline: none;
    }
}

%clearfix {
    *zoom: 1;
    &:after {
        content: '';
        display: table;
        line-height: 0;
        clear: both;
    }
}
