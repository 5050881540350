.container {
    box-sizing: border-box;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.container {
    @extend %clearfix;
}
@media (min-width: 768px) {
    .container {
        width: 766px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 990px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
